@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes textGradient {
  0%, 100% {
    background-position: left center;
  }
  50% {
    background-position: right center;
  }
}

.gradient-text {
  background: linear-gradient(to right, black, blue, black);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300% 100%;
  background-clip: text;
  animation: textGradient 5s linear infinite;
}


.fade-in {
  animation: fadeIn 1s ease-out forwards;
}

.fade-out {
  animation: fadeOut 1s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes loadBar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.fancy-cursive {
  font-family: 'Great Vibes', cursive;
}

html {
  scroll-behavior: smooth;
}

.education-item h3 {
  color: #333; /* Dark grey for strong visibility */
}

.education-item p {
  color: #666; /* Lighter grey for subtext */
}

ul {
  padding-left: 20px; /* Proper indentation for lists */
}

li {
  margin-bottom: 10px; /* Space between list items */
}

.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50; /* High z-index to ensure it is above other content */
}
